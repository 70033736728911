import { useMemo, useState } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import createTimelinePlayer from "astrid-helpers/src/audio/player/timelinePlayer";
import context from "astrid-helpers/src/audioContext";

export default function useArtifactAudioPlayerControls({ chunks }) {
	const [state, setState] = useState({
		action: "stop",
		position: 0,
	});

	const player = useMemo(() => {
		const player = createTimelinePlayer(context);

		for (const chunk of chunks) {
			player.addSound({
				bytes: chunk.bytes,
				position: chunk.start / 1000,
				duration: (chunk.stop - chunk.start) / 1000,
				getBuffer: chunk.getBuffer,
			});
		}

		return player;
	}, [chunks]);

	const play = () => {
		const position = Timeline.getPosition();

		const onTime = (time) => {
			Timeline.setPosition(position + time * 1000);
		};

		player.play(position / 1000);
		player.events.on("time", onTime);

		setState({
			action: "play",
			position: Timeline.getPosition(),
		});
	};

	const stop = () => {
		player.stop();
		player.events.off("time");

		setState({
			action: "stop",
			position: Timeline.getPosition(),
		});
	};

	const preload = (position) => {
		const chunk = chunks.find((chunk) => chunk.start <= position && position <= chunk.stop);

		if (chunk) {
			chunk.getBuffer();
		}
	};

	return {
		play,
		stop,
		action: state.action,
		preload,
	};
}
