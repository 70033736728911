import createDocument from "../../utils/createDocument";
import createDocumentData from "../../utils/createDocumentData";

import { Studio } from "./types/Studio";

export default function createStudio(firebase, data) {
	const studio = Studio.parse(
		createDocumentData(firebase, "studios", {
			...data,
			gen: 2,
		}),
	);

	return createDocument(studio);
}
