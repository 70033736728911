import DiscussionChannelsCollection from "../api/discussion/DiscussionChannelsCollection";
import OrganizationsCollection from "../api/organizations/OrganizationsCollection";

import createFirestoreUtils from "./createFirestoreUtils";

export default class FirestoreAPI {
	constructor(firebase, { plugins, getUser = () => null, debug = false } = {}) {
		this.debug = debug;
		this.firebase = firebase;
		this.firestore = firebase.firestore();

		this.utils = createFirestoreUtils(this.firebase, { debug: this.debug });
		this.plugins = plugins;
		this.getUser = getUser;

		this.organizations = new OrganizationsCollection(this);
		this.discussionChannels = new DiscussionChannelsCollection(this);
	}
}
