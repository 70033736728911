import React from "react";

import ArrowIcon from "astrid-components/lib/components/Assets/Icons/Arrow";
import BookmarkIcon from "astrid-components/lib/components/Assets/Icons/Bookmark";
import PauseIcon from "astrid-components/lib/components/Assets/Icons/Pause";
import PlayIcon from "astrid-components/lib/components/Assets/Icons/Play";
import Button from "astrid-components/lib/components/Inputs/Button";
import Divider from "astrid-components/lib/components/Layout/Divider";
import Flex from "astrid-components/lib/components/Layout/Flex";

import ArtifactAudioPlayerChapterTool from "./ArtifactAudioPlayerChapterTool";
import ArtifactAudioPlayerTime from "./ArtifactAudioPlayerTime";
import ArtifactAudioPlayerTool from "./ArtifactAudioPlayerTool";

export default function ArtifactAudioPlayerToolbar({
	action,
	play,
	stop,
	prevMarker,
	nextMarker,
	activeTool,
	setActiveTool,
}) {
	return (
		<Flex>
			<Flex alignItems="stretch">
				<Flex padding={8}>
					<Button size="small" color="positive" onClick={action === "stop" ? play : stop}>
						{action === "stop" ? <PlayIcon size={20} /> : <PauseIcon />}
					</Button>
				</Flex>

				<Divider vertical />

				<Flex gap={8} padding={8}>
					<ArtifactAudioPlayerTool
						tool={ArtifactAudioPlayerChapterTool}
						activeTool={activeTool}
						onChange={(tool) => setActiveTool(() => tool)}
					>
						<BookmarkIcon />
					</ArtifactAudioPlayerTool>

					<Button size="small" color="primary" onClick={prevMarker}>
						<ArrowIcon style={{ transform: "rotate(90deg)" }} />
					</Button>

					<Button size="small" color="primary" onClick={nextMarker}>
						<ArrowIcon style={{ transform: "rotate(-90deg)" }} />
					</Button>
				</Flex>
			</Flex>

			<Flex alignItems="stretch">
				<Flex padding={8}>
					<ArtifactAudioPlayerTime action={action} />
				</Flex>
			</Flex>
		</Flex>
	);
}
