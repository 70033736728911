import React, { useMemo, useState } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import List from "astrid-components/lib/components/Data/List";
import Divider from "astrid-components/lib/components/Layout/Divider";
import Panel from "astrid-components/lib/components/Layout/Panel";
import Theme from "astrid-components/lib/components/Theme";
import flattenArtifactSilences from "astrid-firestore/src/api/artifacts/flattenArtifactSilences";

import Flex from "../../../../components/Flex/Flex";

import ArtifactAudioPlayerMarkerListItem from "./components/ArtifactAudioPlayerMarkerListItem";
import ArtifactAudioPlayerTimeline from "./components/ArtifactAudioPlayerTimeline";
import ArtifactAudioPlayerToolbar from "./components/ArtifactAudioPlayerToolbar";
import useArtifactAudioPlayerChunks from "./hooks/useArtifactAudioPlayerChunks";
import useArtifactAudioPlayerControls from "./hooks/useArtifactAudioPlayerControls";
import useArtifactAudioPlayerMarkers from "./hooks/useArtifactAudioPlayerMarkers";

export default function ArtifactAudioPlayer({ files, markers, onAddMarker, onUpdateMarker, onRemoveMarker }) {
	const [activeTool, setActiveTool] = useState(null);

	const chunks = useArtifactAudioPlayerChunks({ files });
	const silences = useMemo(() => flattenArtifactSilences(files), [files]);

	const { play, stop, action, preload } = useArtifactAudioPlayerControls({ chunks });

	const { mappedMarkers, jumpToMarker, prevMarker, nextMarker } = useArtifactAudioPlayerMarkers({
		play,
		stop,
		preload,
		markers,
		action,
		silences,
	});

	return (
		<Theme mode="semantic">
			<Flex>
				<Panel style={{ height: 300, flex: 1, margin: -1 }} overflow="hidden" background="secondary">
					<ArtifactAudioPlayerToolbar
						play={play}
						stop={stop}
						action={action}
						prevMarker={prevMarker}
						nextMarker={nextMarker}
						activeTool={activeTool}
						setActiveTool={setActiveTool}
					/>

					<Divider />

					<ArtifactAudioPlayerTimeline
						play={play}
						chunks={chunks}
						action={action}
						silences={silences}
						markers={mappedMarkers}
						activeTool={activeTool}
						setActiveTool={setActiveTool}
						onAddMarker={onAddMarker}
						onUpdateMarker={onUpdateMarker}
					/>

					<Divider />

					<Timeline.Navigation />
				</Panel>

				{mappedMarkers.length > 0 && (
					<Panel style={{ height: 300, flex: 0.2, margin: -1 }} background="secondary">
						<List.Variable
							totalCount={mappedMarkers.length}
							itemContent={(index) => {
								const marker = mappedMarkers[index];

								return (
									<ArtifactAudioPlayerMarkerListItem
										first={index === 0}
										last={index === mappedMarkers.length - 1}
										marker={marker}
										onClick={() => jumpToMarker(marker)}
										onRemove={() => onRemoveMarker({ index, marker })}
									/>
								);
							}}
						/>
					</Panel>
				)}
			</Flex>
		</Theme>
	);
}
