import React from "react";
import { useTranslation } from "react-i18next";

import Trash from "astrid-components/lib/components/Assets/Icons/Trash";
import List from "astrid-components/lib/components/Data/List";
import Button from "astrid-components/lib/components/Inputs/Button";
import Flex from "astrid-components/lib/components/Layout/Flex";
import Text from "astrid-components/lib/components/Text/Text";
import artifactMarkerTypes from "astrid-firestore/src/api/artifacts/constants/artifactMarkerTypes";
import msToTime from "astrid-helpers/src/msToTime";

export default function ArtifactAudioPlayerMarkerListItem({ marker, first, last, onClick, onRemove }) {
	const { t } = useTranslation();

	return (
		<List.Item active={marker.active} first={first} last={last} onClick={onClick}>
			<Flex cursor="pointer" padding={10}>
				<div>
					<Text fontSize={12} lineHeight={1.2} color={marker.safe ? marker.color : "negative"}>
						{msToTime(marker.position, false)} {!marker.safe && `(${t("unsafe", "Unsafe")})`}
					</Text>

					<Text fontSize={14} fontWeight={600}>
						{marker.text}
					</Text>
				</div>

				{marker.type === artifactMarkerTypes.CHAPTER && (
					<Button
						transparent
						size="small"
						color="negative"
						onClick={(e) => {
							e.stopPropagation();
							onRemove();
						}}
					>
						<Trash size={12} />
					</Button>
				)}
			</Flex>
		</List.Item>
	);
}
