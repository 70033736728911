import firebase from "firebase/app";

import { Dropdown } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import updateStudio from "astrid-firestore/src/api/studios/updateStudio";

export default function RestartStudioDropdownItem({ studio, setLoading }) {
	const { t } = useTranslation();

	return (
		<Dropdown.Item
			text={t("restart", "Restart")}
			disabled={studio.occupied}
			onClick={async () => {
				setLoading?.(true);
				await updateStudio(firebase, studio.ref, { restart: true });
				setLoading?.(false);
			}}
		/>
	);
}
