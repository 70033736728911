import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import artifactMarkerTypes from "astrid-firestore/src/api/artifacts/constants/artifactMarkerTypes";

import useArtifactAudioPlayerTimeline from "./useArtifactAudioPlayerTimeline";

export default function useArtifactAudioPlayerMarkers({ play, stop, preload, markers, action, silences }) {
	const { t } = useTranslation();

	const { moveToPosition } = useArtifactAudioPlayerTimeline();

	const [activeIndex, setActiveIndex] = useState(0);

	const mappedMarkers = useMemo(() => {
		const markerProps = {
			[artifactMarkerTypes.CHAPTER]: (chapterNumber) => ({
				color: "positive",
				text: `${t("chapter", "Chapter")} ${chapterNumber}`,
			}),
			[artifactMarkerTypes.SPLIT]: () => ({
				color: "primary",
				text: t("splitPoint", "Split point"),
			}),
		};

		let chapterNumber = 1;

		return markers
			.map((marker, index) => {
				const props = markerProps[marker.type](chapterNumber);

				if (marker.type === artifactMarkerTypes.CHAPTER) {
					chapterNumber++;
				}

				return {
					...marker,
					...props,
					active: index === activeIndex,
				};
			})
			.sort((a, b) => a.position - b.position);
	}, [activeIndex, markers, t]);

	const jumpToMarker = (marker) => {
		if (marker) {
			const { position } = marker;

			const silence = silences.find(({ start, end }) => start <= position && position <= end);
			const index = markers.findIndex(({ id }) => id === marker.id) || 0;
			const next = markers[index + 1];

			setActiveIndex(index);
			moveToPosition(silence ? silence.end - 50 : position);

			if (action === "play") {
				stop();
				play();
			}

			if (next) {
				preload(next.position);
			}
		}
	};

	const prevMarker = () => {
		const marker = markers[activeIndex - 1] || markers[0];

		jumpToMarker(marker);
	};

	const nextMarker = () => {
		const marker = markers[activeIndex + 1] || markers[markers.length - 1];

		jumpToMarker(marker);
	};

	return { mappedMarkers, jumpToMarker, prevMarker, nextMarker };
}
