import firebase from "firebase/app";

import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import createStudio from "astrid-firestore/src/api/studios/createStudio";
import { Studio } from "astrid-firestore/src/api/studios/types/Studio";

import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

export default function CreateStudioForm({ organization, onClose }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { form, error, onSubmit } = useSimpleForm({
		schema: Studio,
		defaultValues: {
			id: "",
			name: "",
			producerId: organization.id,
		},
		onSubmit: (data) => {
			return createStudio(firebase, data);
		},
		onSuccess: ({ id }) => {
			navigate(`../${id}`);
		},
	});

	return (
		<ModalPrompt
			size="tiny"
			error={error}
			header={t("createStudio", "Create studio")}
			onSave={onSubmit}
			onClose={onClose}
			closeOnConfirm={false}
		>
			<Form form={form}>
				<Form.Input
					name="id"
					defaultValue=""
					label={t("studioId", "Studio ID")}
					placeholder="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"
				/>

				<Form.Input name="name" label={t("name")} />
			</Form>
		</ModalPrompt>
	);
}
